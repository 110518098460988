import { render, staticRenderFns } from "./YfnList.vue?vue&type=template&id=75905fec&scoped=true&"
import script from "./YfnList.vue?vue&type=script&lang=js&"
export * from "./YfnList.vue?vue&type=script&lang=js&"
import style0 from "./YfnList.vue?vue&type=style&index=0&id=75905fec&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "75905fec",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default,YfnIcon: require('/home/php1/m-nuxt/components/YfnIcon.vue').default,YfnSwiper: require('/home/php1/m-nuxt/components/YfnSwiper.vue').default,YfnBag: require('/home/php1/m-nuxt/components/YfnBag.vue').default,BizRegister: require('/home/php1/m-nuxt/components/BizRegister.vue').default})
