
export default {
    name: 'YfnRate',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        value: [Number, String],
        quantity: [Number, String],
        options: {
            type: Object,
            default:() => {
                return {}
            }
        }
    },
    data() {
        return {
            defaultOptions: {
                size: 12,
                color: '#96BD2C',
                readonly: true,
                'allow-half': true,
                'void-icon': 'star-o',
                'void-color': '#96BD2C'
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
