import { render, staticRenderFns } from "./YfnSku.vue?vue&type=template&id=71c5d78f&scoped=true&"
import script from "./YfnSku.vue?vue&type=script&lang=js&"
export * from "./YfnSku.vue?vue&type=script&lang=js&"
import style0 from "./YfnSku.vue?vue&type=style&index=0&id=71c5d78f&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71c5d78f",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {YfnImage: require('/home/php1/m-nuxt/components/YfnImage.vue').default})
