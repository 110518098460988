
export default {
    name: 'YfnProduct',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        info: Object,
        options: {
            type: Object,
            default: () => {
                return {}
            }
        }
    },
    data() {
        return {
            currency: {},
            rsOption: {
                classTitle: 'c-ellipsis',
                hasProp: true,
                hasTag: true,
                hasDiscountTag: true,
                hasCustomize: true,
                hasQuantity: true,
                hasOrg: true,
                hasBag:false,
                img: {width: '90px', height: '90px'}
            },
            rsInfo: {},
            bagCompo: {            // 加购组件参数
                product: {},
                options: {
                    visible: false,
                    hasImdAddBag: this.options.hasImdAddBag
                }
            },
        }
    },
    computed: {},
    watch: {
        options: {
            handler(n, o) {
                this.setOption(n, o)
            },
            deep: true,
            immediate: true
        },
        info: {
            handler(n, o) {
                this.setInfo(n, o)
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        this.initialSet()
    },
    mounted() {},
    methods: {
        setOption(obj) {
            const n = JSON.parse(JSON.stringify(obj));
            const img = {
                ...this.rsOption.img,
                ...(n?.img || {})
            };
            this.rsOption = {...this.rsOption, ...n, img}
            this.rsOption.currency && (this.currency = this.rsOption.currency)
        },
        setInfo(obj) {
            const n = JSON.parse(JSON.stringify(obj));
            const lang = this.$storage.get('language');
            this.rsInfo = n

            // 键值对替换
            const key = this.options?.key || {};
            const keyArr = Object.keys(key);
            keyArr.forEach(c => {
                this.rsInfo[c] = this.rsInfo[key[c]]
            })
            // 价格
            if(this.rsInfo.salePrice < this.rsInfo.orgPrice) {
                this.rsInfo.hasOrg = true
                this.rsInfo.priceClass = 'YfnProduct-price--org'
            }
            // 规格
            if(this.rsInfo.propList) {
                let propArr = [];
                this.rsInfo.propList.forEach(list => {
                    propArr.push(list.specVal)
                })
                this.rsInfo.propContent = propArr.join('/')
            }
            // 图片
            if(!this.rsInfo.pic && this.rsInfo.pics) {
                const pic = this.rsInfo.pics[0] || {};
                pic.url && (this.rsInfo.pic = pic.url)
            }
            
            !this.rsInfo.propContent && (this.rsOption.hasProp = false)
            // 折扣标签
            if(!this.rsInfo.productLabel) {
                this.rsInfo.productLabel = []
            }

            // propertyResult
            const propertyResult = this.rsInfo.propertyResult || {}
            const propArr = Object.keys(propertyResult).map(c => {
                return {
                    name: c,
                    ...propertyResult[c][0]
                }
            })
            const propFirst = propArr[0]
            this.rsInfo.propArr = propArr
            this.rsInfo.propFirst = propFirst
            // sku
            const skus = this.rsInfo.skus || []
            const firstSku = skus[0] || {}
            this.rsInfo.selectSku = {}
            if(this.rsOption.hasSkuImg) {
                let specUrlSku = []
                skus.forEach(p => {
                    const specs = p.specs || []
                    const specsObj = specs.find(c => {
                        return c.specUrl && c.specUrl != 'undefined'
                    })
                    if(specsObj) {
                        const findSpecSku = specUrlSku.find(c => {
                            const specUrlTarget = c.specUrlTarget || {}
                            return specUrlTarget.specVal == specsObj.specVal
                        })
                        !findSpecSku && specUrlSku.push({
                            ...p,
                            specUrlTarget: specsObj,
                            specUrl: specsObj.specUrl
                        })
                    }
                })
                this.rsInfo.specUrlSku = specUrlSku
                this.rsInfo.hasSkuImg = !!specUrlSku.length
            }
            this.rsInfo.selectSku = firstSku
            // // 初始化选择

            const selectSkuData = this.setSelectSkuData(firstSku, this.rsInfo)
            Object.keys(selectSkuData).forEach(c => {
                this.rsInfo[c] = selectSkuData[c]
            })
            // if(this.rsInfo.discountRate) {
            //     this.rsInfo.productLabel.unshift({
            //         name: false ? `${this.rsInfo.discountRate} ${this.$translate('OFF')}` : `-${this.rsInfo.discountRate}%`,
            //         color: '#fff',
            //         backgroundColor: '#F8981C'
            //     })
            // }
            this.rsInfo.productLabel.forEach(p => {
                p.style = `color: ${p.color};background: ${p.backgroundColor};`
            })
            // 商品标签
            !this.rsInfo?.activityLabel?.length && (this.rsOption.hasTag = false)
            // 定制
            !this.rsInfo.customizeParams && (this.rsOption.hasCustomize = false)
        },
        // sku选择
        handleSku(obj, pre) {
            const selectSku = pre.selectSku || {}
            if(obj.id == selectSku.id) return
            const target = {
                ...pre,
                ...this.setSelectSkuData(obj, pre)
            }
            this.$set(this,'rsInfo', target)
        },
        // 设置选择数据
        setSelectSkuData(obj = {}, pre) {
            const pics = pre.pics || []
            const firstPics = pics[0] || {}
            const exchangedOrgPrice = pre.exchangedOrgPrice
            const exchangedSalePrice = pre.exchangedSalePrice
            let target = {
                pic: obj.skuPic || firstPics.url || pre.pic,
                selectSku: obj,
                activityLabel: obj.activityLabel || pre.activityLabel || [],
                productLabel: obj.productLabel || pre.productLabel || [],
                productName: obj.productName || obj.skuName || pre.productName
            }
            // 价格
            const orgPrice = obj.costPrice || pre.orgPrice || exchangedOrgPrice?.amount
            const salePrice = obj.marketPrice || pre.salePrice || exchangedSalePrice?.amount
            const hasOrgPrice = orgPrice > salePrice
            target.hasOrgPrice = hasOrgPrice
            target.displayOrgPrice = obj.displayCostPrice || pre.displayOrgPrice || exchangedOrgPrice?.amountWithMoneyFormat
            target.displaySalePrice = obj.displayMarketPrice || pre.displaySalePrice || exchangedSalePrice?.amountWithMoneyFormat
            // seoUrl
            target.seoUrl = obj.seoUrl || pre.seoUrl
            return target
        },
        // 定制商品-服务图预览
        handlePreCustomize(obj) {
            let arr = []
            let index = 0;
            this.rsInfo.customizeParams.forEach(p => {
                p.serviceType == 2 && arr.push(p.inputImage)
            })
            arr.forEach((p, i) => {
                p == obj.inputImage && (index = i)
            })
            this.$ImagePreview({
                images: arr,
                startPosition: index
            })
        },
        // 跳转
        handleProduct() {
            if(!this.rsOption.hasJumpProduct) return
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: this.rsInfo.productCode,
                    seoUrl: this.rsInfo.seoUrl
                }
            })
        },
        handleProductTitle() {
            this.$emit('emit-product-title', this.rsInfo)
            if(!this.rsOption.hasTitleJump) return
            this.$router.push({
                name: 'Product-Seo',
                params: {
                    code: this.rsInfo.productCode,
                    seoUrl: this.rsInfo.seoUrl
                }
            })
        },
        handleBag(obj) { // 加购
            this.bagCompo.product = obj
            this.bagCompo.options.visible = true
        },
        emitSuccess(obj) { // 加购回调
            this.$emit('emit-add', obj)
        },
        // 初始化
        initialSet() {
            if(this.rsOption?.currency){
                this.currency = this.rsOption.currency
            }else{
                this.currency = this.$storage.get('currency') || {}
            }
        }
    },
}
