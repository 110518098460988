

export default {
    name: 'YfnSticky',
    components: {},
    asyncData({ api, cookies, ...context }) { },
    props: {
        options: {
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            tab: {
                options: []
            },
            filter: {
                sort: '',
                dropSort: '',
                dropClass: 'YfnSticky-dropSort--active',
                hasBestActive: false,
                hasPriceActive: false
            },
            filterResult: {        // 筛选后的参数
                num: 0,
                params: {}
            },
            filterCompo: {         // 筛选组件配置
                visible: false,
                hasReset: false,
            },
            sticky: {
                layout: 'icon-layout-two',
                data: {}
            },
            scrollData: {
                hasScrollUp: true,
                lastScrollTop: 0,
            },
            defaultOptions: {
                sort: '',
                sale: '',
                mergeSort: '',
                columns: 2,
                stickyTop: 0,
                hasScrollTop: true
            },
        }
    },
    computed: {},
    watch: {
        options: {
            handler(n, o) {
                // n.sort ?? (n.sort ='')
                // n.sale ?? (n.sale = '')
                // n.mergeSort ?? (n.mergeSort = '')
                // n.hasNav ?? (n.hasNav = false)
                // n.hasSort ?? (n.hasSort = false)
                // n.hasReset ?? (n.hasReset = false)
                // n.columns ?? (n.columns = 2)
                // n.stickyTop ?? (n.stickyTop = 0)
                n.data && (this.sticky.data = JSON.parse(JSON.stringify(n.data)))
                this.filter = JSON.parse(JSON.stringify({ ...this.defaultOptions, ...this.filter, ...n }))
                this.handleSort()
                this.setRest()
                this.setSticky()
            },
            deep: true,
            immediate: true
        },
    },
    created() {
        this.initialSet()
    },
    mounted() {},
    beforeDestroy() {
        if (this.options.hasScrollSticky) {
            window.removeEventListener('scroll', this.scrollFn)
        }
    },
    methods: {
        // tab
        changeType(e) { // 排序
            this.filter.sort = this.filter.dropSort
            this.filter.dropClass = 'YfnSticky-dropSort--active'
            this.handleSort()
            this.setFilterEmit()
        },
        setDrop() {
            this.filter.dropSort = ''
            this.filter.dropClass = ''
        },
        handleSort() { // 排序数据设置
            this.filter.hasBestActive = this.filter.sort == 'saleCount:desc'
            this.filter.hasAscActive = this.filter.sort == 'salePrice:asc'
            this.filter.hasDescActive = this.filter.sort == 'salePrice:desc'
            this.filter.hasPriceActive = this.filter.hasAscActive || this.filter.hasDescActive
        },
        handleBest() { // 排序-畅销
            const descText = 'saleCount:desc'
            this.filter.sort = descText
            this.setDrop()
            this.handleSort()
            this.setFilterEmit()
        },
        handlePrice() { // 排序-价格
            this.filter.sort = this.filter.sort == 'salePrice:asc' ? 'salePrice:desc' : 'salePrice:asc'
            this.setDrop()
            this.handleSort()
            this.setFilterEmit()
        },
        handleAscPrice() { // 排序-价格-升序
            const asc = 'salePrice:asc'
            this.filter.sort = asc
            this.setDrop()
            this.handleSort()
            this.setFilterEmit()
        },
        handleDescPrice() { // 排序-价格-降序
            const desc = 'salePrice:desc'
            this.filter.sort = desc
            this.setDrop()
            this.handleSort()
            this.setFilterEmit()
        },
        handleLayout() { // 列表排版
            const columns = this.filter.columns == 2 ? 1 : 2;
            this.filter.columns = columns
            this.sticky.layout = ['icon-layout-one', 'icon-layout-two'][columns - 1]
            this.setLayoutEmit()
        },
        handleFilter() { // 筛选按钮
            this.filterCompo.visible = true
        },
        emitFilter(obj) { // 筛选弹窗
            this.filterResult.num = obj.form.changeSlideNum ? 1 : 0
            this.filterResult.params = obj.form.params || {}
            // 选中数量
            let select = obj.form.options || {}
            if(obj.form?.params?.filterActivity){
                this.filterResult.num += 1
            }
            if(obj.form?.params?.filterCats){
                this.filterResult.num += 1
            }
            if(obj.form?.params?.filterCommentScore){
                this.filterResult.num += 1
            }
            Object.keys(select).forEach(key => {
                if (select[key]) {
                    let type = Object.prototype.toString.call(select[key])
                    if (type == '[object Array]') {
                        this.filterResult.num += select[key].length
                    } else {
                        this.filterResult.num += 1
                    }
                }
            })
            this.$fnApi.debounce(() => {
                this.$emit('emit-change-filter', this.setParamsEmit())
            }, 50)
        },
        // emit
        getParamsEmit() {
            return { options: this.filter, filterResult: this.filterResult }
        },
        setParamsEmit() {
            this.setUpdate()
            return { options: this.filter, filterResult: this.filterResult }
        },
        setFilterEmit() {
            let mergeSort = [this.filter.sort, this.filter.sale].filter(s => {
                return s
            }).join(',')
            this.filter.mergeSort = mergeSort
            this.filter.hasReset = false
            this.$emit('emit-change-sort', this.setParamsEmit())
        },
        setLayoutEmit() {
            this.$emit('emit-change-layout', this.getParamsEmit())
        },
        // 重置
        setRest() {
            if (!this.filter.hasReset) return
            this.filter.mergeSort = ''
            this.filter.sort = ''
            this.filter.sale = ''
            this.filter.hasReset = true
            this.filterCompo.hasReset = true
            this.setUpdate()
        },
        setUpdate() {
            this.$fnApi.debounce(() => {
                this.$emit('emit-update', {
                    tab: this.tab,
                    filterResult: this.filterResult,
                    options: this.filter
                })
            }, 20)
        },
        // 设置粘性布局
        setSticky() {
            const stickyTag = this.options.stickyTag;
            if (stickyTag && process.client) {
                const getHeight = (e) => {
                    const tag = document.querySelector(e);
                    const tagHeight = tag ? `${tag.clientHeight}px` : 0;
                    return {
                        ...tag,
                        tagHeight
                    }
                };
                const height = getHeight(stickyTag)
                this.filter.stickyTop = height.tagHeight
                let times = 1;
                let clear = setInterval(() => {
                    times += 1
                    const h = getHeight(stickyTag);
                    this.filter.stickyTop = h.tagHeight
                    if (times > 6) {
                        clearInterval(clear)
                    }
                }, 1000)
            }
        },
        // 置顶
        setScrollTop() {
            if (this.filter.hasScrollTop && (this.filter.hasSort || this.filter.hasFilter)) {
                this.$VueScrollTo.scrollTo('body', '', {
                    duration: 0
                })
            }
        },
        // 滚动
        scrollFn() {
            // 滚动条距文档顶部的距离
            let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
            // 滚动条滚动的距离
            let scrollStep = scrollTop - this.scrollData.lastScrollTop;
            // 更新——滚动前，滚动条距文档顶部的距离
            this.scrollData.lastScrollTop = scrollTop;
            if (scrollStep < 0) {
                // 向下
                this.scrollData.hasScrollUp = false
            } else {
                // 向上
                this.scrollData.hasScrollUp = true
            }
        },
        // 初始化
        initialSet() {
            const tabOptions = [{
                text: this.$translate('Recommend'),
                value: '',
                visible: true
            }, {
                text: this.$translate('Best Selling'),
                value: 'saleCount:desc',
                visible: this.options.hasBest
            }, {
                text: this.$translate('New Arrivals'),
                value: 'gmtCreate:desc',
                visible: true
            }, {
                text: this.$translate('Price Low to High'),
                value: 'salePrice:asc',
                visible: this.options.hasPrice
            }, {
                text: this.$translate('Price High to Low'),
                value: 'salePrice:desc',
                visible: this.options.hasPrice
            }, {
                text: this.$translate('Customer Reviews'),
                value: 'scoreAverage:desc',
                visible: true
            }]
            this.tab.options = tabOptions.filter(p => {
                return p.visible
            })
            if (this.options.hasScrollSticky && process.client) {
                window.addEventListener('scroll', this.scrollFn, true)
            }
        }
    },
}
