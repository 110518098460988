
export default {
    name: 'YfnIcon',
    props: {
        name: [String],
        w: [Number, String],
        h: [Number, String],
        size: {
            type: [Number, String],
            default: () => {
                return 24
            }
        },
        opacity: {
            type: [Number, String],
            default: () => {
                return 1
            }
        },
        fill: {
            type: [Number, String],
            default: () => {
                return ''
            }
        }
    },
    data() {
        return {}
    },
    methods: {
        getFill(fill, name) {
            if(fill || !name) return fill
            const defaultFill = {
                'icon-question': '#AAA',
                'icon-check-selected': '#333',
                'icon-edit': '#666',
                'icon-delete': '#666',
                'icon-enlarge': '#666',
                'icon-check': '#999',
                'icon-right': '#999',
                'icon-notify': '#F8981C',
                'icon-newcomer': '#F8981C',
                'icon-track-yellow': '#F8981C',
                'icon-return-yellow': '#F8981C',
                'icon-ensure-yellow': '#F8981C',
                'icon-ensure-green': '#96BD2C',
                'icon-wish-selected': '#96BD2C',
                'icon-agree-selected': '#96BD2C'
            };
            return defaultFill[name] || ''
        },
        handleIcon(e) {
            this.$emit('click', e)
        }
    },
}
