
import Sku from '/utils/common/sku.js'

const sku = new Sku()

export default {
    name: 'YfnSku',
    props: {
        info:{
            type: Object,
            default: () => {
                return {}
            }
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        },
        firstSelect: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            clear: '',
            rsInfo: {},
            target: {},                  // 当前操作项
            sku: {                       // sku组件数据
                list: [],                // sku数据
                mergeList: [],           // sku组合数据
                specs: [],               // 默认sku组合内specs数据
                hasSku: false,           // 是否显示sku
                hasNoneSku: false,       // 是否无规格
                hasOnlySku: false,       // 是否是单规格（单类单规格）
                hasOnlyStyleMultipleSku: false, // 是否单类多规格
                hasMultipleSku: false,   // 是否多规格（多类多规格）
                hasColorSku: false,      // 是否单类多规格-色值
                hasPicSku: false,        // 是否单类多规格-图片
                msg: {},
                minPriceSku: {},         // 最低价格sku数据
                firstSku: {},            // 第一项 sku
                matchMSku: {},           // skuMId 匹配的 sku
                hasMergeTag: true
            },
            mergeList: [],               // sku数据
        }
    },
    watch: {
        info: {
            handler(n, o) {
                if(!Object.keys(n).length) return
                this.getSku()
            },
            deep: true,
            immediate: true
        },
        options: {
            handler(n, o) {
                this.setInitialSkuSelect()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 获取 sku 数据
        getSku() {
            this.setResetSku()
            this.rsInfo = JSON.parse(JSON.stringify(this.info))
            !this.rsInfo.propertyResult && (this.rsInfo.propertyResult = {})
            !this.rsInfo.skus && (this.rsInfo.skus = [])
            if(!this.rsInfo.skus.length) {
                this.setSkuDataEmit()
                return
            }
            // sku 空数据
            this.setSkuEmpty()
            // sku 状态数据
            this.setSkuStatusData()
            // sku 无规格数据
            this.setSkuNoneData()
            if(!this.sku.hasNoneSku) {
                // sku 多规格数据
                this.setSkuExistData()
            }
            // sku 数据整合
            this.setSkuConcatData()
            // sku 数据属性
            this.setSkuPropData()
            // 初始化 sku 数据
            this.setInitialSku()
        },
        // sku空数据
        setSkuEmpty() {
            const propsObj = this.rsInfo.propertyResult || {};
            const propsArr = Object.keys(propsObj) || [];
            const propsLength = propsArr.length;
            const skus = this.rsInfo.skus || [];
            const firstSku = skus[0] || {};
            if(propsLength || !firstSku.specs) return
            const firstSpecs = firstSku.specs[0] || {};
            if(firstSpecs.specName) {
                let skuList = [];
                skus.forEach(p => {
                    p.specs[0] && skuList.push(p.specs[0])
                })
                this.rsInfo.propertyResult[firstSpecs.specName] = skuList
            }
        },
        // sku 状态数据
        setSkuStatusData() {
            const propsObj = this.rsInfo.propertyResult || {};
            const propsArr = Object.keys(propsObj) || [];
            const propsLength = propsArr.length;
            const hasNoneSku = !propsLength;
            this.sku.hasNoneSku = hasNoneSku // 无规格
            this.sku.hasOnlySku = hasNoneSku // 单规格(单类单规格)
            this.sku.hasMultipleSku = propsLength > 1 // 多规格(多类多规格)
            this.sku.hasOnlyStyleMultipleSku = false // 单类多规格
            propsArr.forEach(key => {
                const childPropsArr = propsObj[key] || [];
                const childPropsLength = childPropsArr.length;
                if(!this.sku.hasMultipleSku) {
                    this.sku.hasOnlySku = childPropsLength < 2
                    this.sku.hasOnlyStyleMultipleSku = childPropsLength > 1
                }
            })
        },
        // none 无规格数据
        setSkuNoneData() {
            if(!this.sku.hasNoneSku) return
            const key = '1';
            const val = '2'
            this.sku.list = [{
                key: key,
                id: key,
                name: key,
                list: [{
                    id: val,
                    name: val,
                    specVal: val,
                    code:val
                }]
            }]
            this.sku.specs = [{
                sortNumber: 1,
                specName: key,
                specTone: '',
                specUrl: '',
                specVal: val,
                code:val
            }]
        },
        // exist 含规格数据
        setSkuExistData() {
            const propsArr = Object.keys(this.rsInfo.propertyResult) || [];
            propsArr.forEach(key => {
                let arrList = []
                this.rsInfo.propertyResult[key].forEach(list => {
                    arrList.push({
                        ...list,
                        id: list.code,
                        name: list.specVal
                    })
                })
                this.sku.list.push({
                    key: key,
                    id: key,
                    name: key,
                    list: arrList
                })
            })
        },
        // sku 数据整合
        setSkuConcatData() {
            let setGId = (obj) => { // 获取sku组id
                let result= '';
                this.sku.list.some(a => {
                    result= ''
                    a.list.some(b => {
                        if(obj.code == b.id) {
                            result = a
                            return true
                        }
                    })
                    if(result) {
                        return true
                    }
                })
                return result
            }
            this.info.skus.forEach(item => {
                let mergeList = []
                const specs = this.sku.hasNoneSku ? this.sku.specs : item.specs;
                specs.forEach(list => {
                    mergeList.push({
                        ...list,
                        gId: setGId(list).id,
                        // gId: list.code,
                        id: list.code,
                        name: list.specVal
                    })
                })
                this.sku.mergeList.push({
                    specs,
                    ...item,
                    list: mergeList
                })
            })
        },
        // sku 数据属性设置
        setSkuPropData() {
            this.sku.list.forEach(p => {
                p.list.forEach(c => {
                    c.merge = {}
                    this.sku.mergeList.forEach(d => {
                        d.specs.forEach(e => {
                            if(c.code == e.code) {
                                c.merge = d
                            }
                        })
                    })
                })
            })
        },
        // 初始化sku数据
        setInitialSku() {
            sku.initial({
                list: this.sku.list,
                mergeList: this.sku.mergeList
            }).then(res => {
                this.mergeList = res.list
                this.sku.msg = res.msg
                this.setMergeData()
                this.setMergeSkuData()
                this.setMergeInitial()
                this.setSkuDataEmit()
            })
        },
        // 设置 sku 组合数据
        setMergeData() {
            this.sku.hasColorSku = false
            this.sku.hasPicSku = false
            this.mergeList.forEach(p => {
                p.hasPicUrl = false
                p.list.forEach(c => {
                    // if(!this.sku.hasMultipleSku) {
                        // 色值
                        c.hasColorSku = c.specTone && c.specTone != 'undefined' && c.specTone.includes('#')
                        c.style = {}
                        if(c.hasColorSku) {
                            this.sku.hasColorSku = c.hasColorSku
                            c.style.background = `${c.specTone}`
                        }
                        // 图片
                        c.merge ?? (c.merge = {})
                        const merge = c.merge;
                        const hasPropsPic = false;
                        const hasSkuPic = merge.skuPic;
                        c.hasPicSku = hasPropsPic || !!hasSkuPic
                        c.previewImg = hasPropsPic ? c.specUrl : merge.skuPic
                        c.hasPicUrl = !!c.specUrl && c.specUrl != 'undefined'
                        if(!c.hasColorSku && (c.hasPicSku || hasSkuPic)) {
                            this.sku.hasPicSku = c.hasPicSku
                        }
                        if(c.hasPicSku) {
                            p.hasPicUrl = true
                        }
                        // 特殊处理
                        if(p.name.toLowerCase().includes(this.$translate('Size').toLowerCase())) {
                            this.sku.hasPicSku = false
                        }
                    // }
                })
            })
        },
        // 设置 sku 组数据
        setMergeSkuData() {
            const mergeList = this.sku.mergeList || [];
            const first = mergeList[0];
            this.sku.firstSku = first
            this.sku.minPriceSku = first
            mergeList.forEach(p => {
                if(p.marketPrice < first.marketPrice) {
                    this.sku.minPriceSku = p
                }
            })
            this.sku.matchMSku = mergeList.find(p => {
                return p.id == this.options.skuMId
            }) || {}
        },
        // 设置 sku 组合数据初始化
        setMergeInitial() {
            const minPriceSku = this.sku.minPriceSku;
            this.sku.hasSku = !this.sku.hasOnlySku || this.options.showOnlySku
            const hasInitialId = this.options.skuMId || this.options.skuGId || this.options.skuId;
            const hasInitial = this.sku.hasOnlySku || this.sku.hasOnlyStyleMultipleSku;
            let initialSku = this.firstSelect ? this.sku.firstSku : minPriceSku
            if(this.options.skuMId) {
                initialSku = this.sku.matchMSku
            }
            if((!hasInitialId && hasInitial) || this.firstSelect) {
                this.options.skuMId = initialSku.id
                this.setInitialSkuSelect()
            }
        },
        // 初始化sku选择
        setInitialSkuSelect() {
            if(this.options.skuMId) {
                let filter = this.sku.mergeList.filter(item => {
                    return item.id == this.options.skuMId
                }) || [];
                filter.forEach(item => {
                    item.list.forEach(list => {
                        this.handleSelect({
                            target: {id: list.id},
                            item: {id: list.gId}
                        })
                    })
                })
                return
            }
            if(this.options.skuId && this.options.skuGId) {
                this.handleSelect({
                    target: {id: this.options.skuId},
                    item: {id: this.options.skuGId}
                })
                return
            }
        },
        // sku选择
        handleSelect(obj) {
            if(obj.target.disable) {
                this.mergeList.map(res=>{
                    res.list.map(item=>{
                        item.status = false
                    })
                })
                // return
            }
            sku.match({
                id: obj.target.id,
                gId: obj.item.id,
                hasCancel: false,
                hasOnlySku: this.sku.hasOnlySku
            }).then(res => {
                this.mergeList = res.list
                this.sku.msg = res.msg
                this.target = obj
                this.setSkuDataEmit()
                let r = {...res, hasClick: obj.hasClick}
                r.select && (r.select.hasClick = obj.hasClick)
                if(r.select.seoUrl){
                    this.$emit('emit-sku-select', {...r, target: this.target, sku: this.sku})
                }else{
                    let noSelectName = ''
                    this.mergeList.map(res=>{
                        !res.status?noSelectName=res.name:''
                    })
                    this.$emit('emit-sku-select', {select:{noSelectName:noSelectName}})
                }
            })
        },
        // 重置
        setResetSku() {
            this.sku = {
                list: [],
                mergeList: [],
                specs: [],
                hasSku: false,
                hasNoneSku: false,
                hasOnlySku: false,
                hasOnlyStyleMultipleSku: false,
                hasMultipleSku: false,
                hasColorSku: false,
                hasPicSku: false,
                hasMergeTag: true,
                msg: {},
                minPriceSku: {},
            }
            this.mergeList = []
        },
        // emit
        setSkuDataEmit() {
            this.$emit('emit-sku-data', {
                mergeList: this.mergeList,
                sku: this.sku
            })
        }
    }
}
