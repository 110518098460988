

export default {
    name: 'YfnPopup',
    props: {
        title: {
            type: String,
            default: null
        },
        closeable: {
            type: Boolean,
            default: true
        },
        position: {
            type: String,
            default: 'bottom'
        },
        rsStyle: {
            type: Object,
            default: () => {
                return {}
            }
        },
        safeAreaInsetBottom: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            active: 0,
            defaultStyle: {
                bottom: {width: '100%', 'min-height': '50%', 'max-height': '90%'}
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    methods: {},
}
