
import { directive } from 'vue-awesome-swiper'
import 'swiper/css/swiper.min.css'

export default {
    name: 'YfnSwiper',
    directives: {
        swiper: directive
    },
    props: {
        list: Array,
        swiperStyle: {
            type: Object,
            default: () => {
                return {}
            }
        },
        wrapperStyle: {
            type: Object,
            default: () => {
                return {}
            }
        },
        slideStyle: {
            type: Object,
            default: () => {
                return {}
            }
        },
        options: {
            type: Object,
            default: () => {
                return {}
            }
        },
        name: {
            type: String,
            default: () => {
                return 'swiper1'
            }
        }
    },
    data() {
        return {
            def: {
                pt: {el: '.swiper-pagination', clickable :true},
                nt: {prevEl: '.swiper-button-prev', nextEl: '.swiper-button-next'}
            }
        }
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
        this.getSwiper()
    },
    methods: {
        getSwiper() {
            this.$emit('emit-get-initial', this[this.name])
            return this[this.name]
        }
    },
}
