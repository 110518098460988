
export default {
    name: 'YfnRegion',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        country: Object,
        options: { // -> type 1 州/省 2 市/区
            type: Object,
            default: () => {
                return {}
            }
        },
    },
    data() {
        return {
            tab: {             // tab标签
                id: 1,
                list: []
            },
            search: {          // 搜索
                val: '',
                areaList: []
            },
            area: {            // 地区
                id: '',
                bar: [], // 索引栏
                stateList: [], // 州/省
                cityList: [],  // 市/区
                legendList: [], // 联想搜索数据
                list: [],
                hasCity: true, // 是否存在市/区
                hasAllSelect: false, // 地区是否均选择完
            },
            bar: {
                visible: false,
                anchor: '-86px'
            },
            form: {
                state: '',
                city: ''
            }
        }
    },
    computed: {},
    watch: {
        options: {
            handler(n, o) {
                n.type && (this.tab.id = n.type)
                n.state && (this.form.state =  n.state)
                n.city && (this.form.city =  n.city)
                n.top && (this.bar.anchor = '-' + n.top + 'px')
                this.changeTab()
            },
            deep: true,
            immediate: true
        }
    },
    created() {},
    mounted() {
        this.initialSet()
    },
    methods: {
        // 获取地区接口
        getStateAjax() { // 州/省
            this.$api.common.getState({
                countryCode: this.country.countryCode
            }).then(response => {
                const res = response.result || [];
                this.area.stateList = res
                this.setAreaData()
            })
        },
        getCityAjax() { // 州/市
            this.$api.common.getCity({
                countryCode: this.country.countryCode,
                stateOrProvince: this.form.state
            }).then(response => {
                const res = response.result || [];
                this.area.cityList = res
                if(!this.area.cityList.length) {
                } else {
                    this.setAreaData()
                }
                this.area.hasCity = !(!this.area.cityList.length)
                !this.area.hasCity && this.setEmit()
            })
        },
        // 设置地区选择数据
        setAreaData() {
            let areaList = this.tab.id == 1 ? this.area.stateList : this.area.cityList;
            let areaObj = {}
            this.area.list = []
            this.area.bar = []
            // 联想搜索数据
            this.search.val && (areaList = this.area.legendList)
            // 地区数据处理
            areaList.forEach(item => {
                let oneStr = '', code = '', reg = /^\d{5}$|^\d{5}(-\d{4})$/;
                if(this.tab.id == 1) {
                    oneStr = item.stateOrProvince.slice(0, 1)
                } else {
                    oneStr = item.city.slice(0, 1)
                }
                if(!areaObj[oneStr]) {
                    areaObj[oneStr] = {}
                    areaObj[oneStr].list = []
                    this.area.bar.push({
                        key: oneStr,
                        className: `YfnRegion-country-title-${oneStr}`,
                    })
                }
                // 地区邮编
                if(item.postCode) {
                    code = item.postCode.split(',')[0]
                    !reg.test(code) && (code = '')
                }
                areaObj[oneStr].list.push({
                    ...item,
                    name: this.tab.id == 1 ? item.stateOrProvince : item.city,
                    code: code,
                })
            })
            Object.keys(areaObj).forEach((key, val) => {
                this.area.list.push({
                    title: key,
                    className: `YfnRegion-country-title-${key}`,
                    list: areaObj[key].list
                })
            })
            // 锚点
            this.selectBar()
        },
        // bar
        handleBar(obj) {
            this.$VueScrollTo.scrollTo(`.${obj.className}`, '', {
                container: '.YfnRegion-section',
                offset: 0
            })
        },
        // tab change
        changeTab() {
            if(this.tab.id == 1) {
                if(!this.area.stateList.length) {
                    this.getStateAjax()
                } else {
                    this.setAreaData()
                }
            } else {
                if(!this.form.state) {
                    this.area.list = []
                } else {
                    !this.area.cityList.length ? this.getCityAjax() : this.setAreaData()
                }
            }
        },
        setTab() { // 设置tab
            let i = this.tab.id - 1
            this.$set(this.tab.list, i, {
                ...this.tab.list[i],
                text: this.form[['state', 'city'][i]]
            })
        },
        // 地区选择
        handleRegion(obj) {
            this.form[['state', 'city'][this.tab.id-1]] = obj.name
            // 清除搜索数据
            this.search.val = ''
            this.area.legendList.length = 0
            // 设置tab
            this.setTab()
            if(this.tab.id == 1) {
                this.form.city = ''
                if(!this.options.hasCity) {
                    this.setEmit()
                } else {
                    this.tab.id = 2
                    this.setTab()
                    this.getCityAjax()
                }
            } else {
                // 邮编
                obj.code && (this.form.postCode = obj.code)
                this.setEmit()
            }
        },
        setSelectStatus() { // 设置选择状态
            if(this.options.hasCity) {
                this.area.hasAllSelect = !!(this.form.state && (!this.area.hasCity || this.area.hasCity && this.form.city))
            } else {
                this.area.hasAllSelect = !!this.form.state
            }
        },
        // 联想搜索
        inputSearch(val) {
            this.search.areaList = this.tab.id == 1 ? this.area.stateList : this.area.cityList;
            this.search.areaList = this.tab.id == 1 ? this.area.stateList : this.area.cityList;
            let legendList = this.search.areaList.filter(item => {
                let bool = false
                if(this.tab.id == 1) {
                    bool = item.stateOrProvince.toLocaleLowerCase().includes(val.toLocaleLowerCase())
                } else {
                    bool = item.city.toLocaleLowerCase().includes(val.toLocaleLowerCase())
                }
                return bool
            })
            this.area.legendList = legendList
            this.setAreaData()
            this.$emit('emit-input', this.search)
        },
        // emit
        setEmit() {
            this.setSelectStatus()
            this.$emit('emit-change-region', {
                form: this.form,
                options: this.options,
                area: this.area,
                tab: this.tab,
            })
        },
        // bar 锚点定位
        selectBar() {
            const first = this.area.bar[0] || {};
            if(!first.className) return
            this.$fnApi.debounce(() => {
                this.$VueScrollTo.scrollTo(`.${first.className}`, '', {
                    container: '.YfnRegion-section',
                    offset: -10
                })
            }, 300)
        },
        // 初始化
        initialSet() {
            this.tab.list = [{
                title: this.$translate('State/Province'),
                text: this.form.state,
                id: 1
            }]
            if(this.options.hasCity) {
                this.tab.list.push({
                    title: this.$translate('City/Town'),
                    text: this.form.city,
                    id: 2
                })
            }
        }
    },
}
