
export default {
    name: 'YfnRadio',
    components: {},
    asyncData({api, cookies, ...context}) {},
    props: {
        list: Array,
        dataKey: Object
    },
    data() {
        return {
            value: '',
            reList: [],
            options: {}
        }
    },
    computed: {},
    watch: {
        list: {
            handler() {
                this.setListData()
            },
            deep: true,
            immediate: true
        },
        '$attrs': function() {
            this.setBind()
        }
    },
    created() {},
    mounted() {},
    methods: {
        // 数据
        setListData() {
            const dataKey = this.dataKey || {};
            const list = this.list || [];
            this.reList = list.map(p => {
                let o = {...p};
                Object.keys(dataKey).forEach(c => {
                    o[c] ?? (o[c] = p[dataKey[c]])
                })
                return o
            })
        },
        setBind() {
            const defaultOptions = {
                size: 16,
                type: 'icon',
                'has-cancel': false,
                'label-position': 'left'
            };
            this.options = {
                ...defaultOptions,
                ...this.$attrs,
            }
            this.value = this.options.value
            this.setReListData()
        },
        // 设置
        handleRadio(obj) {
            const hasCancel = this.options['has-cancel'];
            const value = this.value;
            let hasValue = true;
            this.value ?? (hasValue = false)
            const hasEmpty = hasCancel && hasValue && obj.value == this.value;
            const disabled = this.options.disabled || obj.disabled;
            if(!disabled) {
                if(hasEmpty) {
                    this.value = ''
                } else {
                    this.value = obj.value
                }
                this.$emit('input', this.value)
                if((value != obj.value) || hasEmpty) {
                    this.$emit('change', this.value)
                }
            }
            this.$emit('click', obj)
        },
        setReListData() {
            this.reList.forEach(p => {
                const hasSelect = p.value == this.value;
                const checkedColor = this.options['checked-color'];
                const voidColor = this.options['void-color'];
                const disabled = this.options.disabled || p.disabled;
                const hasRight = this.options['label-position'] == 'right';
                p.liClass = [
                    'YfnRadio-radio',
                    {'YfnRadio-radio-right': hasRight},
                    {'YfnRadio-radio-active': hasSelect},
                    {'YfnRadio-radio-disabled': disabled}]
                p.liStyle = {
                    'border-radius': this.options.radius,
                    'color':  hasSelect ? checkedColor : voidColor,
                    'border-color': hasSelect ? checkedColor : voidColor
                }
                p.iconName = hasSelect ? 'icon-check-selected' : 'icon-check'
                p.iconFill = hasSelect ? checkedColor : voidColor
            })
        }
    },
}
